import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

import analysisCard from "../ToolsCard.vue";

import Button from "@/components/button";
import Input from "@/components/input";
import Table from "@/components/table";
import Tab from "@/components/tab";
import NeoTabsSwitch from "@/components/tabs-switch";
import Loader from "@/components/loader";
import DropdownAnalysis from "@/components/dropdown-analysis";
import VueMultiselect from "@/components/vue-multiselect";
// import "vue-multiselect/dist/vue-multiselect.min.css";
import "@/assets/css/multiselect.scss";
import axios from "../../../../axios/osint.js";
import {setUsernamesDropdownData, convertToCSV, csvFileName} from "../../../../utils/functions";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {DateTime} from "luxon";
import JsonCSV from "vue-json-csv";
import NeoAnalyse from "@/components/analyse";
import moment from "moment";
const ImagePlaceholder = () => import("@/components/image-placeholder");
import toolsDisclaimer from "@/components/tools-disclaimer";

export default {
    name: "name-search",
    components: {
        "neo-input": Input,
        "neo-button": Button,
        "neo-table": Table,
        "neo-tab": Tab,
        "neo-tabs-switch": NeoTabsSwitch,
        "font-awesome-icon": FontAwesomeIcon,
        "neo-loader": Loader,
        "neo-dropdown-analysis": DropdownAnalysis,
        VueMultiselect,
        "analysis-card": analysisCard,
        "download-csv": JsonCSV,
        NeoAnalyse,
        ImagePlaceholder,
        toolsDisclaimer,
    },
    props: {
        toolData: {
            type: Array,
            default: () => [],
        },
        tool: {
            type: String,
            default: "",
        },
        toolSearchQueries: Array,
    },
    data() {
        return {
            searchItem: "",
            nameSearches: [],
            loaderController: {
                nameSearch: {
                    loading: false,
                    success: false,
                    filterLoading: false,
                    filterSuccess: false,
                },
            },
            errorMessage: "",
            alertMessage: {
                success: null,
                error: null,
            },
            case_id: "",
            allNameSearch: [],
            filters: {},
            headers: [
                {
                    text: "",
                    value: "details",
                },
            ],
            itemKey: "name",
            searchCaseFilter: "",
            selectable: false,
            hasAction: false,
            searchKeyword: "",
            selectedTab: "details",
            selectedItem: null,
            linkedinInfo: [],
            twitterInfo: [],
            githubInfo: [],
            gitlabInfo: [],
            instagramInfo: [],
            youtubeInfo: [],
            isLoading: false,
            showResult: false,
            csvData: [],
            DateTime,
            selectVal: "",
            searchClicked: false,
            request_id: "",
        };
    },
    watch: {
        allNameSearch(newVal, prevVal) {
            if (newVal) {
                this.$emit("searchResult", {data: newVal, source: "name_search", searchType: "name_search_query"});
            }
        },
        "$route.query.q"(to, from) {
            if (to !== from && to !== this.searchKeyword) {
                this.redirectToQuery();
            }
        },

        // selectedItem() {
        //     this.showResult = false;
        // },
    },
    async mounted() {
        this.case_id = this.$route.params.id;
        const dataRes = this.getAllToolsData?.find((n) => n["tool-name"] === "name-search-tool");
        if (dataRes) {
            Object.entries(dataRes).forEach(([key, value]) => {
                this[key] = value;
            });
            this.redirectToQuery(dataRes);
        }
        this.getToolData();
    },
    beforeDestroy() {
        this.SET_ALL_TOOLS_DATA({...this._data, "tool-name": "name-search-tool"});
    },
    methods: {
        ...mapMutations(["SET_ALL_TOOLS_DATA"]),
        ...mapActions(["getAllCaseNames"]),

        async getToolData() {
            this.nameSearches = await this.getDropdownData("name");
        },

        async getDropdownData(field) {
            let data = this.toolData.filter((user) => user.val_type === field);
            return data;
        },

        getOptionsList(list) {
            return [
                {
                    group: "Name",
                    categories: this[list],
                },
            ];
        },

        handleChecked(data) {
            let allChecked = false;
            this.$emit("updateToolData", "name-search", [...this.nameSearches], data);
            allChecked = [...this.nameSearches]?.length ? ([...this.nameSearches].findIndex((n) => !n.visited) > -1 ? false : true) : false;

            this.$emit("handleChecked", allChecked);
        },

        convertToCSV(arr) {
            return convertToCSV(arr);
        },

        csvFileName(query, section) {
            return csvFileName(this.tool, query, section);
        },

        startLoader(section) {
            this.loaderController[section].success = false;
            this.loaderController[section].loading = true;
        },
        successLoader(section) {
            this.loaderController[section].success = true;
            this.loaderController[section].loading = false;
        },
        resetLoader(section) {
            this.loaderController[section].success = false;
            this.loaderController[section].loading = false;
        },

        getTime(item) {
            let date = new Date(item.birthday);
            return date && item.birthday ? date.getDate() + "/" + (parseInt(date.getMonth(), 10) + 1) + "/" + date.getFullYear() : "";
        },

        getPlatformIcon(platform) {
            let social = this.getSocialPlatforms.find((social) => social.key == platform);
            if (social) return social.icon_data;
            else {
                return require(`@/assets/icons/${platform}.svg`);
            }
        },
        setInput() {
            this.searchKeyword = this.selectedItem.value;

            // this.search();
        },

        setTheCustomInput(value) {
            this.searchKeyword = value;
            this.selectedItem = {
                value: value,
            };
            // this.search();
        },

        async search() {
            this.getSocialPlatforms.forEach((el) => (el.expanded ? delete el.expanded : ""));
            this.linkedinInfo = [];
            this.twitterInfo = [];
            this.githubInfo = [];
            this.gitlabInfo = [];
            this.instagramInfo = [];
            this.youtubeInfo = [];
            this.showResult = false;
            this.searchItem = this.searchKeyword;
            if (this.searchKeyword) {
                this.$emit("searched", {data: {name: this.searchKeyword}, source: "name_search", searchType: "name"});
                this.isLoading = true;
                const headers = {
                    "x-tool-name": this.$route.params.toolName,
                    "x-visited": true,
                };
                this.searchClicked = true;
                const reqBody = {
                    query: this.searchKeyword,
                };
                const {data} = await axios.post("/linkedin/search/people", reqBody, {
                    headers,
                });
                this.linkedinInfo = data?.people ?? [];
                this.request_id = data?.query_id;
                this.redirectTo();
                const reqTwitter = axios.post(
                    "/twitter/user/search",
                    {
                        query: this.searchKeyword,
                        page: 1,
                    },
                    {
                        headers,
                    }
                );
                const reqGithub = axios.post("/tools/github/search/user", reqBody, {
                    headers,
                });
                const reqGitlab = axios.post("/tools/gitlab/people", reqBody, {
                    headers,
                });
                const reqInstagram = axios.post("/instagram/search", reqBody, {
                    headers,
                });
                const reqYoutube = axios.post("/youtube/search", reqBody, {
                    headers,
                });
                Promise.allSettled([reqTwitter, reqGithub, reqGitlab, reqInstagram, reqYoutube]).then(([twitter, github, gitlab, instagram, youtube]) => {
                    this.twitterInfo = twitter?.value?.data?.data ?? [];
                    this.githubInfo = github?.value?.data?.data?.items ?? [];
                    this.gitlabInfo = gitlab?.value?.data?.data ?? [];
                    this.instagramInfo = instagram?.value?.data?.data?.data ?? [];
                    console.log(this.instagramInfo);
                    this.youtubeInfo = youtube?.value?.data?.items ?? [];
                    this.$emit("searchResult", {data: [{twitter: this.twitterInfo}, {github: this.githubInfo}, {gitlab: this.gitlabInfo}, {instagram: this.instagramInfo}, {linkedin: this.linkedinInfo}], source: "name_search", searchType: "name"});
                    this.isLoading = false;
                });
                // await axios.post(
                //     "/tools/query",
                //     { query: this.searchKeyword },
                //     {
                //         headers: {
                //             "x-tool-name": this.$route.params.toolName,
                //             "x-visited": true,
                //         },
                //     }
                // );
                this.setSearchedSkypeUser(this.searchKeyword);
                this.SET_ALL_TOOLS_DATA({...this._data, "tool-name": "name-search-tool"});
                this.showResult = true;
            } else {
                this.$toast.error("Please enter a valid input");
            }
        },

        setSearchedSkypeUser(searchKeyword) {
            const data = {
                value: searchKeyword,
                platform: "name-search",
                val_type: "name",
                visited: true,
            };
            let url = `research-methodology/${this.case_id}/tools/profiles`;
            let isSuccess = setUsernamesDropdownData(url, data);
            // if (isSuccess) {
            let dataItem = this[`nameSearches`].find((dataItem) => {
                return dataItem.value === searchKeyword;
            });
            if (dataItem) dataItem["visited"] = true;
            else if (data?.value) {
                this[`nameSearches`].push(data);
                this.$emit("addCustomData", data);
            }
            this.handleChecked(data);
            // }
        },

        redirectTo() {
            let query = {};
            if (this.request_id)
                query = {
                    query_id: this.request_id,
                };
            this.$router.push({path: this.$route.path, query}).catch((err) => {});
        },

        redirectToQuery(dataRes) {
            //redirect to same query
            if (this.$route?.query?.query_id) {
                let {query_id} = this.$route.query;
                let selected_query = this.toolSearchQueries?.find((el) => el._id === query_id);

                const value = Object.values(selected_query?.query)[0];

                this.searchKeyword = value;
                this.selectedItem = {
                    value: value,
                };
                this.selectVal = value;
                if (!dataRes) this.search();
            } else {
                this.redirectTo();
            }
        },
        handleExpand(socialPlatform, index) {
            socialPlatform.expanded = !socialPlatform.expanded;
            this.$forceUpdate();
        },
        fetchLength(platform) {
            let count = 0;
            switch (platform) {
                case "youtube":
                    count = this.youtubeInfo.length;
                    break;
                case "linkedin":
                    count = this.linkedinInfo.length;
                    break;
                case "instagram":
                    count = this.instagramInfo.length;
                    break;
                case "github":
                    count = this.githubInfo.length;
                    break;
                case "twitter":
                    count = this.twitterInfo.length;
                    break;
                case "gitlab":
                    count = this.gitlabInfo.length;
                    break;
                default:
                    break;
            }
            return count;
        },
        async encodeURL(url, id) {
            let api = "";
            api = `${process.env.VUE_APP_OSINT_ENGINE_URL}/api/v1/instagram/image/${btoa(url)}`;
            let image = "";
            image = await axios.get(api);
            if (image?.data?.data) document.getElementById(id).src = image.data.data;
        },
        getJoined(item) {
            let date = new Date(item.creation_date);
            // let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            return date ? moment(date).format("DD MMMM YYYY, HH:mm") : "";
            // return date ? date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear() : '' + " " + date.getHours() + ":" + date.getMinutes() ;
        },
        onSelect({value}) {
            this.selectVal = value;
            this.$refs["analysis-tool-input"].search = value;
        },

        close() {
            this.$refs["analysis-tool-input"].search = this.selectVal;
            this.searchKeyword = this.selectVal;
            this.selectedItem = {
                value: this.selectVal,
            };
        },
        handleChange(event) {
            if (event != this.selectVal) {
                this.showResult = false;
                this.searchClicked = false;
            }
            this.selectVal = event;
        },
    },
    computed: {
        ...mapGetters(["getReadOnlyMode", "getResearchData", "getAllToolsData", "getCaseNames", "getSocialPlatforms"]),

        fetchAllData() {
            if (this.getResearchData.personalDetails?.length || this.getResearchData.socialProfiles?.length) {
                this.getToolData();
            }
        },
        socialProfiles() {
            return this.getSocialPlatforms.filter((n) => n.key === "linkedin" || n.key === "twitter" || n.key === "github" || n.key === "gitlab" || n.key === "instagram" || n.key === "youtube");
        },
    },
};
